video {
    object-fit: cover；
}

.conferencebanner {
    width: 100%;
    height: 100%;

    // background: url(../../assets/image/banner-3.jpg) no-repeat;
    // background-size: 100% 100%;

    h1 {
        font-size: 66px;
        font-weight: 800;
        color: #abd1ff;
        padding-top: 174px;
        text-align: center;
        margin-bottom: 30px;
    }

    h2 {
        font-size: 40px;
        font-weight: 800;
        color: #83bbff;
        line-height: 56px;
        text-align: center;
    }
}

.conference {
    width: 100%;
    height: 100%;
    background-color: #f1f5f8;
    margin-top: -4px;


    .conference-content {
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        background: #f1f5f8;

        .conference-top {
            height: 480px;
            width: 100%;
            display: flex;

            .conference-top-left {
                width: 633px;
                height: 100%;

                p {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 800;
                    color: #0068b7;
                    line-height: 28px;
                    margin-bottom: 23px;
                }

                a {
                    color: #000000;
                }

                table,
                table tr td {
                    border: 1px solid #c9c9c9;
                    font-size: 16px;
                    height: 50px;

                }

                table {

                    width: 100%;
                    height: 100%;
                    border-collapse: collapse;

                    .td-center {
                        text-align: center;
                        width: 159px;
                        background: #dbeaf5;
                    }

                    .td-padding {
                        padding-left: 36px;
                    }
                }
            }

            .conference-top-right {
                width: 516px;
                height: 100%;
                margin-top: 20px;
                margin-left: 30px;
                // background: #ccc;

                img {
                    width: 517px;
                    height: 425px;
                }
            }
        }

        h2 {
            width: 100%;
            height: 75px;
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 75px;
            border-bottom: 1px solid #c9c9c9;
            margin-bottom: 37px;

            span {
                color: #255c9f;
            }
        }

        .conference-bottom {
            width: 1200px;
            height: 100%;
            // background-color: #0068b7;
            display: flex;

            .conference-bottom-right {
                width: 160px;
                height: 251px;
                background: #ffffff;
                border-radius: 0px 24px 24px 0px;
                box-shadow: 0px 2px 4px 0px rgba(115, 112, 112, 0.35);
                margin-right: 60px;
                margin-bottom: 50px;

                ul {
                    padding-top: 30px;
                }

                button {
                    width: 161px;
                    height: 60px;
                    text-align: center;
                    line-height: 60px;
                    color: #0068b7;
                    font-weight: 800;
                    font-size: 16px;
                    border: 0;
                    background: #fff;

                }

                #plenary {
                    background:  linear-gradient(90deg,#00b1ff, #1b6bdb);
                    color: #fff;
                }

                // #forum{
                //     background: #fff;
                //     color: #0068b7;
                // }

                // li:hover {
                //     width: 161px;
                //     height: 60px;
                //     background:  linear-gradient(90deg,#00b1ff, #1b6bdb);;
                //     line-height: 60px;
                //     color: #fff;
                //     text-align: center;
                //     font-weight: 800;
                //     font-size: 16px;
                // }
            }

            .conference-bottom-left {
                height: 100%;
                width: 100%;
                // border-top: 1px solid #c9c9c9;
                padding-top: 48px;

                // background: #83bbff;
                #forums {
                    display: none
                }

                #activitys {
                    display: none;
                }
            }
        }
    }


}