@font-face{
  font-family: 'PingFang'; // --自定义名字就好
  src: url("../src/assets/font/DIGITAL-Regular.ttf");
}
@font-face{
  font-family: 'PingFang-Bold'; // --自定义名字就好
  src: url("../src/assets/font/DINAlternateBold.ttf"); 
}
body {
  margin: 0;
  font-family: 'PingFang','PingFang-Bold'
    ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
