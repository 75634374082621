/* 学会 */

.lifeElectronic {
    width: 100%;
    height: 798px;
    background-color: #ebf2f6;
    text-align: center;
    opacity: 1;

    h2 {
        padding-top: 50px;
        font-size: 44px;
        // font-weight: 800;
        color: #0068b7;
        line-height: 62px;
        font-weight:normal;
        // margin-top: 50px;
    }

    p {

        font-size: 40px;
        // font-weight: 800;
        text-align: center;
        color: #b1ddfe;
        line-height: 56px;
    }


    .lifeElectronic-div {
        display: flex;
        width: 100%;
        // height: 300px;
        margin: 0 auto;
        justify-content: center;

        img {
            // margin-top: -93px;
        }
        p {
            text-indent: 2em
        }
    }


   

    @keyframes introduceP {
        0% {
            transform: translateY(70px);

        }

        100% {
            transform: translateY(0px);
        }
    }

    .introduce-p {
        width: 821px;
        font-size: 18px;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 34px;
        text-align: left;
        margin: 0 auto;
        position: relative;
        // left: 275px;
        top: 106px;
        /*绑定动画名*/
        animation-name: introduceP;
        /*持续时间*/
        animation-duration: 1.5s;
    }

}

.introduce {
    display: flex;
    width: 1200px;
    margin: 0 auto;

    .left {
        width: 50%;

        img {
            width: 100%;
            height: 100%;
        }

    }

    .right {
        width: 50%;
        margin-top: 157px;



    }
}

/* 年会 */
.introduce-body {
    width: 100%;
    height: 600px;
    background-color: #00408f;
}

.annualMeeting {
    width: 100%;
    height: 600px;
    background-color: antiquewhite;

    button {
        width: 240px;
        height: 80px;
        background: linear-gradient(180deg, #8dd636, #539009);
        border-radius: 12px;
        box-shadow: 0px 4px 4px 0px rgba(19, 75, 23, 0.50);
        border: 0px;
        // margin-left: 35px;
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
        line-height: 40px;
        cursor: pointer;

    }
}

h1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.annualMeeting-p {
    font-size: 44px;
    font-weight: 700;
    color: #ffffff;
    line-height: 62px;
    margin-bottom: 38px;
}

/* 倒计时 */
.countdown {
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 36px;
    font-family: PingFang;

    span {
        color: #67cd13;
        font-size: 64px;
    }
}

/* 大会报告 */
.report-body {
    width: 100%;
    height: 1261px;
    background: url(../../assets/image/specialist.jpg) no-repeat;
    background-size: 100% 100%;

}

.report {
    width: 1200px;
    height: 1261px;
    margin: 0 auto;

    // background-color: rgb(244, 166, 64);

    h1 {
        padding-top: 50px;
        font-size: 44px;
        // font-weight: 800;
        text-align: justify;
        color: #0068b7;
        line-height: 62px;
        font-weight:normal;
    }

    p {

        font-size: 40px;
        // font-weight: 800;
        text-align: center;
        color: #b1ddfe;
        line-height: 56px;
    }


    .report-top {
        width: 1200px;
        height: 320px;
        // background-color: antiquewhite;
        display: flex;

        p {
            text-align: left;
        }


        .report-top-left {
            height: 320px;
            width: 960px;

            .name {
                font-size: 26px;
                font-weight: 700;
                color: #00408f;
                line-height: 40px;
            }

            .title {
                width: 14%;
                height: 22px;
                background: #00408f;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
            }

            .introduce {
                font-size: 16px;
                font-weight: 400;
                color: #4f4f4f;
                line-height: 34px;
                width: 851px;
                float: left;

                // img {
                //     width: 100%;
                //     height: 100%;
                // }
            }
        }

        .report-top-right {
            height: 320px;
            width: 240px;

            img {
                width: 100%;
                height: 100%;
            }

        }
    }

    .report-bottom {
        width: 1200px;
        // height: 941px;
        // background-color: aqua;
        overflow: hidden;
    }

    #personage {
        opacity: 0;
        position: relative;
        top: -1067px;
        left: 0px;
        z-index: 99;
        display: flex;
        // background-color: #f6f7f9;
    }
}

/* 论坛版块 */
.forum-body {
    width: 100%;
    height: 598px;
    background: #ebf2f6;
    margin: 0 auto;

    .forum {
        width: 1200px;
        height: 598px;
        margin: 0 auto;


        h1 {
            padding-top: 50px;
            font-size: 44px;
            // font-weight: 800;
            font-weight:normal;
            color: #0068b7;
            line-height: 62px;
        }

        p {

            font-size: 40px;
            // font-weight: 800;
            text-align: center;
            color: #b1ddfe;
            line-height: 56px;
            margin-bottom: 47px;
        }

        .forum {
            float: left;
            // width: 255px;
            height: 330px;

            // background-color: red;
            .forumdiv {
                width: 255px;
                height: 330px;
               

                .forumimg {
                    width: 255px;
                    height: 290px;
                    background-color: #fff;
                    img {
                        margin-left: 28px;
                        width: 200px;
                        height: 200px;
                        // border: 1px solid #000;
                        margin-top: 40px;
                        // margin-top: 10px;
                    }
                }

                .forump {
                    font-size: 18px;
                    font-weight: 700;
                    text-align: center;
                    color: #ffffff;
                    line-height: 40px;
                    height: 40px;
                    background: #0d3878;
                    border-radius: 0px 0px 6px 6px;

                }


            }

           


        }

    }

}


/* 往期回顾 */
.retrospect-boday {
    width: 100%;
    background-color: #fff;
}

.retrospect {
    height: 605px;
    width: 1200px;
    // background-color: #ccc;
    margin: 0 auto;

    .retrospect-top {
        width: 1200px;
        height: 108px;

        h1 {
            padding-top: 50px;
            font-size: 44px;
            // font-weight: 800;
            color: #0068b7;
            line-height: 62px;
            font-weight:normal;
        }

        p {

            font-size: 40px;
            // font-weight: 800;
            text-align: center;
            color: #b1ddfe;
            line-height: 56px;
        }
    }

    .retrospect-bottom {
        width: 1200px;
        display: flex;
        margin-top: 81px;

        img {
            width: 116px;
            height: 116px;
        }

        p {
            font-size: 26px;
            font-weight: 500;
            color: #000000;
            line-height: 37px;
        }

        .count {
            font-size: 40px;
            font-weight: 700;
            text-align: center;
            color: #00408f;
            line-height: 79px;

            background: -webkit-linear-gradient(-90deg,#2fcfff, #0628cf);
            

            -webkit-background-clip: text;

            -webkit-text-fill-color: transparent;

        }

        .retrospect-left {
            display: flex;
            justify-content: space-around;
            align-items: center;

        }

        .retrospect-number {
            width: 107px;
            // height: 269px;
        }

        .retrospec {
            width: 583px;
            height: 337px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        div {
            width: 600px;
            text-align: center;

            p {
                margin-top: 18px;
            }
        }
    }
}

/* 载荷空间 */
.spaceflight {
    width: 100%;
    height: 600px;
    // background-color: #b1ddfe;


    h1 {
        font-size: 66px;
        // font-weight: 700;
        color: #ffffff;
        line-height: 40px;
        padding-top: 201px;
        font-weight: normal;
        margin-bottom: 40px;
        text-shadow: 0px 4px 4px 0px #000000; 
    }

    p {
        font-size: 24px;
        // font-weight: 700;
        color: #ffffff;
        line-height: 33px;
        text-align: center;

    }

    button {
        width: 256px;
        height: 70px;
        background: linear-gradient(180deg, #7dbaff, #0964ca);
        border-radius: 12px;
        border: 0;
        font-size: 28px;
        font-weight: 700;
        color: #ffffff;
        line-height: 40px;
        display: block;
        margin: 0 auto;
        margin-top: 42px;
        cursor: pointer;

    }
}

/* 合作伙伴 */
.cooperation-body {
    width: 100%;
    background-color: #fff;
}

.cooperation {
    width: 1200px;
    height: 600px;
    margin: 0 auto;
    // background: #ccc;

    h1 {
        font-size: 44px;
        // font-weight: 800;
        color: #0068b7;
        line-height: 62px;
        padding-top: 50px;
        font-weight:normal;
    }

    p {

        font-size: 40px;
        // font-weight: 800;
        text-align: center;
        color: #b1ddfe;
        line-height: 56px;
        margin-bottom: 66px;
    }
    .div-img{
        width: 370px;
        height: 66px;
        float: left;
        margin:30px 10px;
        img{
            width: 350px;
            height: 66px;
           
        }
        img:hover{
            width: 370px;
            height: 66px;
           
        }
    }
    
}