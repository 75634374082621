.navlist {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background: #00408f;
   

    a {
        text-decoration: none;
        color: #FFFFFF;
    }
.navlist{
    display: flex;
}
    .list {
        text-align: center;

        height: 60px;
        width: 100px;
        line-height: 60px;
    }

    .light {
        font-weight: bold;
        height: 60px;
        width: 100px;
        display: block;
        background: linear-gradient(180deg, #8dd636, #539009);
    }
}

