.footer {
    height: 340px;
    width: 100%;

    .footer-top {
        height: 300px;
        width: 100%;
        background: #00408f;

        div {
            width: 1200px;
            margin: 0 auto;

            ul {
                float: left;
                color: #ffffff;
                margin-top: 70px;
                padding: 0 50px;
                height: 136px;
                border-right: 1px solid #486aaf;

                li:first-child {
                    font-size: 22px;
                    font-weight: 700;
                    color: #ffffff;
                    line-height: 30px;
                    margin-bottom: 23px;
                }

                li {
                    font-size: 16px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 22px;
                }
            }

            .gzh {
                float: right;
                width: 113px;
                height: 113px;
                margin-top: 70px;

                img {
                    width: 113px;
                    height: 113px;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    text-align: center;
                    color: #ffffff;
                    margin-top: 13px;
                }
            }


        }
    }

    .footer-bottom {
        height: 40px;
        width: 100%;
        background: #003577;
        text-align: center;
        a{
            color: #ffffff;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 40px;
        }
    }
}