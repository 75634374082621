.bj{
    background: url(../../assets/image/aerospace.jpg) no-repeat;
    background-size: 100% 100%;
    background-attachment:fixed;
    // background-color: red;
}
.breadth{
    width: 100%;
    margin: 0 auto;
}
@keyframes bannertop {
    0% {
        transform: translateY(253px);

    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes bannerbottom {
    0% {
        transform: translateY(253px);

    }

    100% {
        transform: translateY(0px);
    }
}
@keyframes butto {
    0% {
        transform: translateY(90px);

    }

    100% {
        transform: translateY(0px);
    }
}

.homebanner {
    // width: 100%;
    height: 560px;
    background: url(../../assets/image/banner6.jpg) no-repeat;
    background-size: 1920px 100%;

    .bannerTop {
        position: relative;
        left: 275px;
        top: 183px;
        /*绑定动画名*/
        animation-name: bannertop;
        /*持续时间*/
        animation-duration: 1.5s;

    }

    .bannerBottom {
        position: relative;
        left: 275px;
        top: 193px;
        animation-name: bannerbottom;
        animation-duration: 2.5s;
        
    }
    .banner-div{
        width: 240px;
        height: 80px;
        background: linear-gradient(180deg, #8dd636, #539009);
        border-radius: 12px;
        box-shadow: 0px 4px 4px 0px rgba(19, 75, 23, 0.50);
        position: relative;
        left: 286px;
        top: 222px;
        animation-name: butto;
        animation-duration: 3.5s;
        display: block;
        text-align: center;
        line-height: 80px;
    }
    a {
        display: block;
        font-size: 28px;
        color: #fff;
        width: 240px;
        height: 80px;
        
        
    }
    a :hover{
        background: linear-gradient(180deg, #5EAC00, #427900);
        
    }

}