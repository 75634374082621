.applybanner {
    // width: 100%;
    height: 560px;
    background: url(../../assets/image/banner-3.jpg) no-repeat;
    background-size: 1920px 100%;

    p{
        font-size: 66px;
        // font-weight: 800;
        color: #abd1ff;
        padding-top: 174px;
        text-align: center;
        margin-bottom: 30px;
    }

    h2 {
        font-weight:normal;
        font-size: 40px;
        // font-weight: 800;
        color: #83bbff;
        line-height: 56px;
        text-align: center;
    }
}
.apply {
    width: 100%;
    height: 760px;
    

    .apply-content {
        width: 1200px;
        margin: 0 auto;
        height: 760px;
        background: #fff;

        h2 {
            height: 75px;
            font-size: 16px;
            font-weight: 400;
            
            line-height: 75px;
            a{
                color: #000000;
            }

            span {
                color: #255c9f;
            }
        }

        .apply-count {
            width: 1178px;
            height: 617px;
            border: 1px solid #c9c9c9;
            margin: 0 auto;
            padding-top: 27px;
            display: flex;

            .apply-count-left {
                width: 538px;
                height: 578px;
                border-right: 1px solid #979797;

                p {
                    font-size: 16px;
                   
                    color: #4f4f4f;
                    line-height: 24px;
                    text-indent: 2em;
                    // margin-bottom: 40px;
                    padding: 0 15px;
                }
                ul>li{
                    font-size: 16px;
                    color: #4f4f4f;
                }
            }

            .apply-count-right {
                width: 639px;
                height: 578px;
                background: url(../../assets//image/apply1.png) no-repeat;
                // background-size: 100% 100%;


                p {
                    font-size: 44px;
                    font-weight: 700;
                    color: #4c4c4c;
                    line-height: 62px;
                    text-align: center;
                    margin-bottom: 38px;
                }

                button {
                    width: 240px;
                    height: 80px;
                    background: linear-gradient(180deg, #8dd636, #539009);
                    border-radius: 12px;
                    box-shadow: 0px 4px 4px 0px rgba(19, 75, 23, 0.50);
                    border: 0;
                    margin-bottom: 36px;
                    font-size: 28px;
                    font-weight: 700;
                    text-align: center;
                    color: #ffffff;
                    line-height: 40px;
                    cursor: pointer;
                }

                /* 倒计时 */
                .countdowns {
                    font-size: 32px;
                    font-weight: 700;
                    color: #000;
                    text-align: center;

                    span {
                        color: #67cd13;
                        font-size: 36px;
                    }
                }

                .apply-count-right-top {
                    margin-top: 252px;
                    background: linear-gradient(180deg,#ffffff 44%, #e6f2fa 100%);
                    a{
                        color: #fff;
                    }
                }
            }

        }

    }
}