/* 分论坛 */
.subforum {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .subforum-div {
        width: 314px;
        height: 149px;
        // background-image: url(../../assets//icon/lines.svg) no-repeat right bottom ;
        // background: linear-gradient(90deg, #00b1ff, #1b6bdb);
        border-radius: 8px;
        // background-color: #00b1ff;
        background: #00b1ff url(../../assets//icon/lines.svg) no-repeat right bottom;
        box-shadow: 0px 2px 4px 0px rgba(147, 147, 147, 0.50);
        margin: 0 6px 16px;
        position: relative;
       
    }

    p {
        // font-size: 16px;
        // font-weight: 400;
        color: #fff;
        padding: 21px 0 0 26px;
        // margin-bottom: 15px;
    }

    .title {
        font-size: 19px;
        display: block;
        width: 250px;
        // float: left;
    }

    .subforum-p {
        position: absolute;
        left: 3px;
        top: 80px;

        .personage {
            font-size: 18px;
            float: left;

        }

        .personage1 {
            font-size: 18px;
            margin-left: 10px;
        }
    }



    .subforum-left {
        width: 50%;
        border-right: 1px solid #979797;
    }

    .subforum-right {
        p {
            margin-left: 100px;

        }
    }

}
.forums-body{
    background: #f1f5f8;
    width: 100%;
}
.forums-top {
    width: 1178px;
    margin: 0 auto;

    .titles {
        font-size: 30px;
        text-align: center;
        font-weight: 800;
        color: #00408f;
    }

    .introduces {
        width: 1178px;
        height: 240px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 33px;
        margin-top: 22px;
        img{
            margin-left: 50px;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            color: #4f4f4f;
            margin-left: 66px;
            margin-right: 58px;
        }
    }

    .moderators {
        font-size: 20px;
        color: #00408f;
        float: right;
    }

    table,
    table tr td {
        margin-top: 39px;
        border: 1px solid #c9c9c9;
        font-size: 16px;
        height: 50px;

    }

    table {
        margin-top: 39px;
        margin-bottom: 35px;
        width: 100%;
        height: 100%;
        border-collapse: collapse;


    }
    .ant-table-thead th{
        height: 50px;
        border: 1px solid #c9c9c9;
        background: #dbeaf5;
    }

    .speaker {
        // width: 542px;
        // height: 267px;
        // float: left;

        img {
            width: 180px;
            height: 267px;
            float: left;
            margin-right: 34px;
        }

        .speaker-p {
            margin-top: 56px;
            .name-p {
                font-size: 28px;
                color: #00408f;
            }

            .title-p {
                // width: 14%;
                // height: 22px;
                // background: #00408f;
                font-size: 14px;
                color: #000;
            }

            .introduce-p {
                font-size: 14px;
                color: #4f4f4f;
            }

        }
    }
    h2 {
        width: 100%;
        height: 75px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 75px;
        // border-bottom: 1px solid #c9c9c9;
        margin-bottom: 37px;
    
        span {
            color: #255c9f;
        }
    }
}



/* 大会报告 */
/* 会员日活动 */